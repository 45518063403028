//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Message, Radio, RadioGroup, Image, Upload, Button, Form, FormItem, Dialog, Input, MessageBox, Tag } from "element-ui";
import { mapState, mapActions } from "vuex";
import { ctrlOrder, ctrlPay, ctrlBase, ctrlCpn } from "@/controller";
import { Invoice, GoodsItem, CheckOutList, CouponGet } from "@/components";
export default {
    components: {
        [RadioGroup.name]: RadioGroup,
        [Radio.name]: Radio,
        [Image.name]: Image,
        [Upload.name]: Upload,
        [Button.name]: Button,
        [Form.name]: Form,
        [FormItem.name]: FormItem,
        [Dialog.name]: Dialog,
        [Input.name]: Input,
        [Tag.name]: Tag,
        Invoice,
        GoodsItem,
        CheckOutList,
        CouponGet,
    },
    data() {
        return {
            info: {}, //商品詳情
            statDtl: {}, //訂單狀態所包含的訊息
            payList: [], //付款方式列表
            payInfoShow: false, //匯款證明彈窗
            countCode: "", //匯款帳戶後5碼
            payInfoForm: {
                countCode: null,
                imgFile: null,
                imageUrl: null,
            }, //
            refundVisible: false, //已付款取消訂單彈窗
            refundSeason: "",
            isLogin: false, //是否登入
            allowanceShow: false, //折讓單彈窗
            allowance: {
                billFile: null,
                billUrl: null,
                passbookFile: null,
                passbookUrl: null,
            },
            // retList: [], // 根據訂單編號返回退貨列表 -- 部分退貨
            retInfo: {}, //  根據訂單編號返回退貨列表 -- 部分退貨 單筆
            newCpnlist: [], //優惠券列表
        };
    },
    created() {
        this.getDetail();
        this.getPayList();
    },
    watch: {
        info(newVal, oldVal) {
            //獲取訂單狀態所包含的訊息
            this.statDtl = ctrlOrder.getOrderStatDtl(newVal.SoInfo);
        },
    },
    computed: {
        ...mapState({
            configInfo(state) {
                return state.base.configInfo;
            },
            //validateMsg
            msg(state) {
                return state["validate"];
            },
        }),
        // 修改匯款訊息權限
        updPayInfoPermit() {
            if (this.Common.isExist(this.info.SoInfo) && ["pay"].includes(this.info.SoInfo.stat)) {
                return true;
            }
        },
        // 修改發票權限
        updInvInfoPermit() {
            if (this.Common.isExist(this.info.SoInfo) && ["ship", "shiping"].includes(this.info.SoInfo.stat)) {
                return true;
            }
        },
        //是否設定正確的付款方式
        isSetPayNbr() {
            if (this.Common.isExist(this.info.SoInfo) && this.Common.isExist(this.payList)) {
                for (let i of this.payList) {
                    if (i.payNbr == this.info.SoInfo.payNbr) {
                        return true;
                    }
                }
            }
        },
        //付款方式名稱
        payName() {
            if (this.Common.isExist(this.info.SoInfo) && this.Common.isExist(this.payList)) {
                for (let i of this.payList) {
                    if (i.payNbr == this.info.SoInfo.payNbr) {
                        return i.name;
                    }
                }
            }
        },
        //是否線上付款
        isOnline() {
            if (this.Common.isExist(this.info.SoInfo) && this.Common.isExist(this.payList)) {
                for (let i of this.payList) {
                    if (i.payNbr == this.info.SoInfo.payNbr) {
                        return i.isOnline;
                    }
                }
            }
        },
        //是否為上傳折讓單 -- 1未上傳折讓單
        // isNoUpOrder() {
        //     if (this.Common.isExist(this.retList)) {
        //         return this.retList.some((item) => item.retStat == 1);
        //     }
        // },
    },
    mounted() {},
    methods: {
        //獲取商品詳情
        async getDetail() {
            ["orderd", "orderc"].includes(this.$route.name) ? (this.info = await ctrlOrder.getDesDetail(this.$route.params)) : (this.info = await ctrlOrder.getDetail(this.$route.params));
            if (this.info?.SoInfo?.isPartRet) this.getRetList1();
            if (this.info.SoInfo.stat == "pledge") {
                setTimeout(() => {
                    //已付款 領券
                    this.collectCouponByOrder();
                }, 1500);
            }
        },
        //獲取付款方式列表
        async getPayList() {
            if (this.$route.name == "orderd") return;
            this.payList = await ctrlPay.getPayList();
        },
        //根據訂單編號返回退貨狀態
        async getRetList1() {
            const data = await ctrlOrder.getRetList1({ soNbr: this.info.SoInfo.soNbr });
            // this.retList = data.RetList;
            this.retInfo = data.RetList.find((item) => item.retStat == 1);
            // this.retList = [
            //     {
            //         name: "a",
            //         retStat: "1",
            //     },
            //     {
            //         name: "b",
            //         retStat: "1",
            //     },
            //     {
            //         name: "c",
            //         retStat: "1",
            //     },
            // ];
            // var a = this.retList.find((item)=>  item.retStat == 1)
            console.log(this.retInfo);
        },
        //匯款帳號輸入
        async cfmPayInfo() {
            this.$refs.payInfoForm.validate(async (valid) => {
                if (!valid) {
                    console.log("error submit!!");
                    return false;
                }
                const data = await ctrlPay.updPayInfo({ soNbr: this.info.SoInfo.soNbr, payRef: this.payInfoForm.countCode, Upload: this.payInfoForm.imgFile });
                if (data) {
                    Message.success("上傳成功");
                    this.getDetail();
                    this.payInfoShow = false;
                }
            });
        },
        //上傳折讓單
        async upAllowanceInfo() {
            if (!this.allowance.billFile) return Message.warning("請上傳折讓單圖檔");
            if (this.retInfo.isUpload2) {
                if (!this.allowance.passbookFile) return Message.warning("請上傳退款賬號銀行存薄圖檔");
            }
            const data = await ctrlOrder.upAllowanceInfo({ retNbr: this.retInfo.retNbr, Upload1: this.allowance.billFile, Upload2: this.allowance.passbookFile });
            if (data) {
                this.getDetail();
                this.allowanceShow = false;
            }
        },
        //脫敏訂單檢測是否本人
        isChecklogin() {
            if (["orderd", "orderc"].includes(this.$route.name) && this.info.loginMsg) {
                MessageBox.alert(`${this.info.loginMsg}`).then(() => {
                    this.$router.push("/account/login");
                });
                return false;
            }
            return true;
        },

        //待付款 取消訂單
        async cancelOrder() {
            if (!this.isChecklogin()) return false;
            MessageBox.confirm("確定要取消訂單嗎？", "提示", {
                confirmButtonText: "確定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(async () => {
                    const data = await ctrlOrder.cancelOrder({ soNbr: this.info.SoInfo.soNbr });
                    if (data) {
                        Message.success("訂單已取消");
                        this.getDetail();
                    }
                })
                .catch();
        },
        //立即付款
        goPay(soNbr) {
            if (!this.isChecklogin()) return false;
            ctrlBase.gaSentEvent("訂單詳情操作", "立即付款", ctrlBase.getPtNameStr(this.info.SodList));
            this.$router.push("/order/pay/" + soNbr);
        },
        //退貨服務
        goAfter(soNbr) {
            if (!this.isChecklogin()) return false;
            ctrlBase.gaSentEvent("訂單詳情操作", "申請售後服務", ctrlBase.getPtNameStr(this.info.SodList));
            this.$router.push("/order/refund/" + soNbr);
        },
        //已付款取消訂單
        cancelOrder2() {
            if (!this.isChecklogin()) return false;
            ctrlBase.gaSentEvent("訂單詳情操作", "取消訂單-已付款", ctrlBase.getPtNameStr(this.info.SodList));
            this.refundVisible = true;
        },
        //已付款取消訂單
        async refundHandle() {
            if (!this.refundSeason.trim()) return Message.error("取消原因不能為空");
            ctrlBase.gaSentEvent("訂單詳情操作", "取消訂單-未付款", ctrlBase.getPtNameStr(this.info.SodList));
            const data = await ctrlOrder.refund({ soNbr: this.info.SoInfo.soNbr, rsn: this.refundSeason });
            if (data) {
                Message.success("訂單已取消");
                this.getDetail();
                this.refundVisible = false;
            }
        },
        allowanceBillChange(res, file) {
            this.allowance.billFile = res.raw;
            this.allowance.billUrl = URL.createObjectURL(res.raw);
        },
        allowancePassbookChange(res, file) {
            this.allowance.passbookFile = res.raw;
            this.allowance.passbookUrl = URL.createObjectURL(res.raw);
        },
        handleAvatarSuccess(res, file) {
            this.payInfoForm.imgFile = res.raw;
            this.payInfoForm.imageUrl = URL.createObjectURL(res.raw);
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === "image/jpeg";
            const isGIF = filesList[i].raw.type === "image/gif";
            const isPNG = filesList[i].raw.type === "image/png";
            const isBMP = filesList[i].raw.type === "image/bmp";
            const isLt4M = file.size / 1024 / 1024 < 4;
            if (!isJPG && !isGIF && !isPNG && !isBMP) {
                this.$message.error("上傳圖片必須是JPG/GIF/PNG/BMP 格式!");
            }
            if (!isLt4M) {
                this.$message.error("上傳頭像圖片大小不能超過 4MB!");
            }
            return isJPG && isGIF && isPNG && isBMP && isLt4M;
        },
        //物流查詢頁面
        openWL(url) {
            window.open(url);
        },
        copy(value) {
            this.Common.copyText(value);
            Message.success("複製成功");
        },
        async collectCouponByOrder() {
            const data = await ctrlCpn.collectCouponByOrder({ soNbr: this.info.SoInfo.soNbr  });
            if (data) {
                this.newCpnlist = data;
                this.$refs.couponGet.couponShow = true;
            }
        },
    },
};
